export const jsonToCsv = ({
  rows,
  columns,
}: {
  rows: Record<string, number | string | null>[];
  columns: string[];
}): string => {
  const headers = columns.map((col) => `"${col}"`).join(',');

  const dataRows = rows.map((row) => columns.map((column) => `"${row?.[column] || ''}"`).join(','));

  return [headers, ...dataRows].join('\n');
};
