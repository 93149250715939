import { type FrameElement } from 'data/reports/types';
import { create } from 'zustand';
import { type PlanTableChartConfig, type ColumnWidthMap } from './types';

export interface ReportsStore {
  isInFullScreen: boolean;
  isHyperLinkPopoverVisible: boolean;
  isToolbarVisible: boolean;
  chartColumnWidthMap: {
    [chartId: number]: ColumnWidthMap;
  };
  planTableEditConfig: {
    [chartId: number]: PlanTableChartConfig | undefined;
  };
  chartDrillDownConfig: {
    [chartId: number]: {
      row: string[];
      rowFrame: FrameElement[];
    }[];
  };
  isPDFExportInProgress: boolean;
  openConversationCellIdentifier: string | undefined;

  setChartColumnWidthMap: (id: number, map: ColumnWidthMap) => void;
  setChartDrillDownConfig: (
    id: number,
    config: { row: string[]; rowFrame: FrameElement[] },
  ) => void;
  setPlanTableEditConfig: (id: number, config?: PlanTableChartConfig) => void;

  setIsInFullScreen: (val: boolean) => void;
  setIsHyperLinkPopoverVisible: (val: boolean) => void;
  setIsToolbarVisible: (val: boolean) => void;
  setIsPDFExportInProgress: (val: boolean) => void;
  setOpenConversationCellIdentifier: (val: string | undefined) => void;
}

export const useReportsStore = create<ReportsStore>()((set, get) => ({
  chartColumnWidthMap: {},
  planTableEditConfig: {},
  chartDrillDownConfig: {},
  isInFullScreen: false,
  isHyperLinkPopoverVisible: false,
  isToolbarVisible: true,
  isPDFExportInProgress: false,
  openConversationCellIdentifier: undefined,

  setChartColumnWidthMap: (chartId, map) =>
    set((state) => ({
      chartColumnWidthMap: {
        ...state.chartColumnWidthMap,
        [chartId]: map,
      },
    })),
  setPlanTableEditConfig: (chartId, config) =>
    set((state) => ({
      planTableEditConfig: {
        ...state.planTableEditConfig,
        [chartId]: config,
      },
    })),

  setChartDrillDownConfig: (chartId: number, config: { row: string[]; rowFrame: FrameElement[] }) =>
    set((state) => {
      const updateConfig = (state.chartDrillDownConfig[chartId] || []).filter(
        (v) => !v.row.join('~').includes(config.row.join('~')),
      );

      return {
        chartDrillDownConfig: {
          ...state.chartDrillDownConfig,
          [chartId]: [...updateConfig, { row: config.row, rowFrame: config.rowFrame }],
        },
      };
    }),

  setIsInFullScreen: (isInFullScreen) => set({ isInFullScreen }),
  setIsToolbarVisible: (isToolbarVisible) => set({ isToolbarVisible }),
  setIsHyperLinkPopoverVisible: (isHyperLinkPopoverVisible) => set({ isHyperLinkPopoverVisible }),
  setIsPDFExportInProgress: (isPDFExportInProgress) => set({ isPDFExportInProgress }),
  setOpenConversationCellIdentifier: (openConversationCellIdentifier) =>
    set({ openConversationCellIdentifier }),
}));
