import { type IRowNode } from 'ag-grid-community';
import { type CheckboxChangeEvent } from 'antd/es/checkbox';
import { Checkbox } from 'components/ui/atomic-components';
import { type ID } from 'data';
import { type ReactElement } from 'react';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import styled from 'styled-components';
import { bulkSelectRows } from './utils';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
`;

interface Props {
  rowId: ID;
  node: IRowNode;
  className?: string;
}

export const SelectorCheckbox = ({ rowId, node, className }: Props): ReactElement => {
  const selectedRowIds = useListStoreContext((s) => s.selectedRowIds);
  const setSelectedRowIds = useListStoreContext((s) => s.setSelectedRowIds);
  const listStore = useListStoreInstance();

  const handleOnChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    const { gridApi, lastSelectedRow, setLastSelectedRow } = listStore.getState();

    if (checked) {
      setSelectedRowIds([...selectedRowIds, rowId]);
      setLastSelectedRow(rowId);

      if (e.nativeEvent.shiftKey && selectedRowIds.length >= 1) {
        bulkSelectRows({
          gridApi,
          lastSelectedRow,
          currentSelectedRow: rowId,
          addRowToSelectedRow: (rowIds) => {
            setSelectedRowIds([...new Set([...selectedRowIds, rowId, ...rowIds])]);
          },
        });
      }
    } else {
      setSelectedRowIds(selectedRowIds.filter((id) => id !== rowId));
    }
  };

  return (
    <Wrapper className={className}>
      <Checkbox
        checked={selectedRowIds.includes(rowId)}
        size="extraSmall"
        onChange={handleOnChange}
      />
    </Wrapper>
  );
};
