import { type RelativeRangeType } from 'components/ui/atomic-components/time-range-panel/v2/relative-panel/types';
import { DateTimeFormats } from 'config/constants';
import { DateFilterPresetName } from 'data/bootstrap/types';
import dayjs from 'dayjs';
import { type DateFilterOption } from './types';

interface SelectedDatesProps {
  filters?: DateFilterOption[];
  startDate?: string | null;
  endDate?: string | null;
  dateOption?: string | null;
}

export const getSelectedFilter = (
  selectedFilter: [string, string] | null,
  options: DateFilterOption[],
): DateFilterOption | undefined => {
  if (!selectedFilter) {
    return options.find((option) => option.isDefault);
  }

  const existingFilterOption = options.find(
    (option) => option.value[0] === selectedFilter[0] && option.value[1] === selectedFilter[1],
  );

  if (existingFilterOption) {
    return existingFilterOption;
  }

  return {
    label: CustomRangeLabel,
    value: selectedFilter,
    name: DateFilterPresetName.CustomRange,
  };
};

export const formatDateRangeText = (
  [startDate, endDate]: [string, string],
  joiner?: string,
): string => {
  const parsedStartDate = dayjs(startDate);
  const parsedEndDate = dayjs(endDate);

  if (
    parsedStartDate.month() === parsedEndDate.month() &&
    parsedStartDate.year() === parsedEndDate.year()
  ) {
    return [parsedStartDate, parsedEndDate]
      .map((date) => date.format(DateTimeFormats.HumanReadableDate))
      .join(joiner ? ` ${joiner} ` : ' -> ');
  }

  return [parsedStartDate, parsedEndDate]
    .map((dateString) => dayjs(dateString).format(DateTimeFormats.HumanReadableMonthYear))
    .join(joiner ? ` ${joiner} ` : ' -> ');
};

export const getSelectedDates = ({
  filters,
  startDate,
  endDate,
  dateOption,
}: SelectedDatesProps): [string, string] | null => {
  if (startDate && endDate && dateOption === DateFilterPresetName.CustomRange) {
    return [startDate, endDate];
  }

  if (dateOption) {
    return filters?.find((option) => option.name === dateOption)?.value ?? null;
  }

  return null;
};

export const CustomRangeLabel = 'Custom Range';
export const RelativeRangeLabel = 'Relative Range';

export const getDefaultDateFilter = (options: DateFilterOption[]): [string, string] => {
  return (
    options.find((o) => o.isDefault)?.value || [
      dayjs().startOf('month').format(DateTimeFormats.APIDate),
      dayjs().endOf('month').format(DateTimeFormats.APIDate),
    ]
  );
};

export const getActualFromRelativeRange = (dateRange: RelativeRangeType, format: string) => {
  const { startDateDelta, endDateDelta } = dateRange;

  const now = dayjs();
  const startDate = now.subtract(startDateDelta, 'month');
  const endDate = now.add(endDateDelta, 'month');

  const formattedStartDate = startDate.format(format);
  const formattedEndDate = endDate.format(format);

  return [formattedStartDate, formattedEndDate] as [string, string];
};
