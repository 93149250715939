import { type ColDef } from 'ag-grid-community';
import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { Modal, MoreActionsDropdown } from 'components/ui/atomic-components';
import {
  DeleteConfirmWithUsage,
  ResourceUsageModal,
  useResourceUsageWidget,
} from 'components/ui/resource-usage-widget';
import { useDimensionGroupMutations } from 'data/modelling/dimension-group/hooks/use-dimension-group-mutations';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { AccessAction } from 'data/roles/permissions';
import dayjs from 'dayjs';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { b } from 'styles/html-elements';
import { downloadResponseData, jsonToCsv } from 'utils';
import { formatName } from 'utils/data-formatter';
import { downloadGridAsExcel } from 'utils/grid';
import { queueMacroTask } from 'utils/queue-macro-task';
import { DT_DEFAULT_UNIQUE_KEY } from '../../constants';
import { DimensionGroupGrid } from '../../crud-components/dimension-group-create-edit-form/grid';
import { GridTopBar } from '../../grid-top-bar';
import { AccessVisibilityInfo } from '../../right-section/secondary-top-bar/access-visibility';
import { RemoveAccessControlModal } from '../../right-section/secondary-top-bar/more-actions/remove-access-control-modal';
import { SensitiveInfoWarning } from '../../right-section/secondary-top-bar/sensitive-info-warning';
import { EditDimensionGroupModal } from './edit-dimension-group-modal';
import { getMoreActionsOptions } from './more-actions-menu';

const DimGrpDuplicatesModal = styled(Modal)<{ $height: string }>`
  .ant-modal-body {
    padding: ${({ theme }) => theme.spacing[16]};
    height: ${({ $height }) => $height};
    overflow: auto;
  }

  &.ant-modal .ant-modal-content .ant-modal-footer {
    border-top: none;
  }
`;

interface Props {
  dimensionGroup: DimensionGroup;
}

export const DimensionGroupTopNav = ({ dimensionGroup }: Props): ReactElement => {
  const gridApi = useListStoreContext((s) => s.gridApi);
  const accessAction = useListStoreContext((s) => s.accessAction);

  const { deleteDimensionGroup } = useDimensionGroupMutations();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [removeAccessModalVisible, setIsRemoveAccessModalVisible] = useState(false);
  const [openDuplicateRowsModal, setOpenDuplicateRowsModal] = useState(false);
  const [dimGrpDuplicatesData, setDimGrpDuplicatesData] = useState<DimensionGroup>();

  const isReadOnly =
    accessAction !== AccessAction.FullAccess ||
    isInVersionMode([VersionAccessModes.View, VersionAccessModes.ScopedEdit]);

  const [isMoreActionsMenuOpen, setIsMoreActionsMenuOpen] = useState(false);
  const { usageWidgetMenuItem, usageModalProps, isTryingToCloseWhileUsageFetch, closeUsageModal } =
    useResourceUsageWidget({
      name: dimensionGroup.name,
      displayName: dimensionGroup.displayName || '',
      type: 'DIMENSION_GROUPS',
      closeMenu: () => setIsMoreActionsMenuOpen(false),
    });

  const onDownloadAsExcel = () => {
    downloadGridAsExcel({
      gridApi,
      fileName: `${dimensionGroup.name}-${dayjs().format('YYYY-MM-DD_HHmmss')}`,
      columnKeys: gridApi
        ?.getColumnDefs()
        ?.filter((col: ColDef) => !col?.hide)
        ?.slice(1) // skip the # column
        ?.map((col: ColDef) => col.colId || ''),
    });
  };

  const menuItems = getMoreActionsOptions({
    usageWidgetMenuItem,
    onDelete: () => setOpenDeleteModal(true),
    onEdit: () => setOpenEditModal(true),
    onDownload: onDownloadAsExcel,
    isReadOnly,
  });

  const handleDelete = async () => {
    await deleteDimensionGroup.mutateAsync({ ...dimensionGroup });

    setOpenDeleteModal(false);
  };

  const onDownloadDimGrpDuplicates = () => {
    const uniqueKeyDisplayName = dimGrpDuplicatesData?.uniqueKeyColumns.join('-') ?? '';
    const columns =
      dimGrpDuplicatesData?.columns.map((col) => {
        if (col.colName === DT_DEFAULT_UNIQUE_KEY) {
          return uniqueKeyDisplayName;
        }

        return col.colName;
      }) ?? [];

    const rows = (dimGrpDuplicatesData?.rows ?? [])?.map((row) => ({
      ...row,
      [uniqueKeyDisplayName]: row?.[DT_DEFAULT_UNIQUE_KEY],
    }));

    const csv = jsonToCsv({
      rows,
      columns,
    });

    downloadResponseData({
      fileName: `${formatName(dimensionGroup.name)} - duplicate rows.csv`,
      data: csv,
    });
  };

  return (
    <>
      <GridTopBar
        isDerivedList
        isReadOnly={isReadOnly}
        listName={dimensionGroup.displayName || ''}
        rightItems={
          <>
            <AccessVisibilityInfo />

            <SensitiveInfoWarning />

            <MoreActionsDropdown
              menu={{ items: menuItems }}
              open={isMoreActionsMenuOpen}
              onOpenChange={(toOpen) => {
                queueMacroTask(() => {
                  if (isTryingToCloseWhileUsageFetch(toOpen)) {
                    return;
                  }

                  setIsMoreActionsMenuOpen(toOpen);
                });
              }}
            />
          </>
        }
      />

      <EditDimensionGroupModal
        dimensionGroup={dimensionGroup}
        dimGrpDuplicatesData={dimGrpDuplicatesData}
        open={openEditModal}
        setDimGrpDuplicatesData={setDimGrpDuplicatesData}
        setOpen={setOpenEditModal}
        setOpenDuplicateRowsModal={setOpenDuplicateRowsModal}
        onDownloadDimGrpDuplicates={onDownloadDimGrpDuplicates}
      />

      <DeleteConfirmWithUsage
        displayName={dimensionGroup.displayName || ''}
        name={dimensionGroup.name}
        noUsagesMessage={
          <FormattedMessage
            id="delete_modal_formula_del.list.question"
            values={{ list: formatName(dimensionGroup.name), b }}
          />
        }
        open={openDeleteModal}
        title={<FormattedMessage id="lists.delete_list_modal.title" />}
        type="DIMENSION_GROUPS"
        onCancel={() => setOpenDeleteModal(false)}
        onOk={handleDelete}
      />

      <ResourceUsageModal usageProps={usageModalProps} onClose={closeUsageModal} />

      <RemoveAccessControlModal
        isDerivedList
        visible={removeAccessModalVisible}
        onCancel={() => setIsRemoveAccessModalVisible(false)}
      />

      {dimGrpDuplicatesData && (
        <DimGrpDuplicatesModal
          $height={`${Math.min(
            document.body.clientHeight - 200,
            (dimGrpDuplicatesData?.rows?.length ?? 0) * 32 + 240,
          )}px`}
          hideFooter
          open={openDuplicateRowsModal}
          title={<FormattedMessage id="dimension_groups.duplicate_rows_modal.title" />}
          width={Math.min(
            document.body.clientWidth - 300,
            dimGrpDuplicatesData.columns.length * 200 + 120,
          )}
          onCancel={() => setOpenDuplicateRowsModal(false)}
        >
          <DimensionGroupGrid dimensionGroup={dimGrpDuplicatesData} />
        </DimGrpDuplicatesModal>
      )}
    </>
  );
};
