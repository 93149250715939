import { type InputRef, type FormInstance } from 'antd';
import { ReactComponent as HelpCircleFilledIcon } from 'assets/v2/help-circle-filled.svg';
import { useChipColoring } from 'components/modules/modelling/_commons/dimension-chip-coloring/use-chip-coloring';
import {
  AsyncDimensionsSelect,
  type DimensionSelectOption,
} from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select';
import { MultiLabelComponent } from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select/multi-label-component';
import { OptionComponent } from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select/option-component';
import { Form, IconShell, Input, Select, Tooltip } from 'components/ui/atomic-components';
import { ColumnBasedFilters } from 'components/ui/column-based-filters';
import { type VisualQueryFilterRule } from 'data/big-query';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { isEmpty } from 'lodash';
import { useMemo, type ReactElement, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { type MultiValue } from 'react-select';
import styled from 'styled-components';
import { defaultTheme } from 'styles/theme';
import { formatName } from 'utils/data-formatter';
import { getDetailedTableName } from 'utils/dimensions';
import { onChangeWithSpaceHandling } from '../utils';
import { DuplicateRowsAlert } from './duplicate-rows-alert';
import { type FormProps } from './types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  overflow: auto;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.black400};
  font-size: ${({ theme }) => theme.fontSize.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const StyledColumnBasedFilters = styled(ColumnBasedFilters)`
  .column-based-filters-header-item {
    padding: 0;
  }

  .column-based-filters-rule-card-item {
    max-width: 450px;
  }
`;

const columnOptionMapper = (option: DimensionSelectOption) => ({
  value: option.value,
  label: formatName(option.name),
  parentSourceDisplayName: option.parentSourceDisplayName,
});

interface Props {
  form: FormInstance<FormProps>;
  initialValues?: Partial<FormProps>;
  editMode?: boolean;
  className?: string;
  duplicatesData?: DimensionGroup;
  setOpenDuplicateRowsModal?: React.Dispatch<React.SetStateAction<boolean>>;
  onDownloadDimGrpDuplicates?: () => void;
}

export const DimensionGroupCreateEditForm = ({
  form,
  initialValues,
  editMode,
  className,
  duplicatesData,
  setOpenDuplicateRowsModal,
  onDownloadDimGrpDuplicates,
}: Props): ReactElement => {
  const groupDimensions = Form.useWatch('groupDimensions', form);

  const dims = useMemo(() => groupDimensions?.map((d) => d.value) || [], [groupDimensions]);
  const chipColoringScheme = useChipColoring({ dims });

  const uniqueKeyOptions = (groupDimensions || []).map(columnOptionMapper);

  const inputRef = useRef<InputRef>(null);

  const onGroupDimensionsChange = (newValue: MultiValue<DimensionSelectOption>) => {
    form.setFieldsValue({ groupDimensions: newValue as DimensionSelectOption[] });

    const filters = form.getFieldValue('filters') as VisualQueryFilterRule[];

    if (isEmpty(filters)) {
      return;
    }

    const fqTableNameSet = new Set(
      (newValue as DimensionSelectOption[]).map((dim) => getDetailedTableName(dim.value)),
    );

    const updatedFilters = filters.filter((f) =>
      fqTableNameSet.has(getDetailedTableName(f.column)),
    );

    // If any column is updated, check if the filter is valid, and update the form values
    if (updatedFilters.length !== filters.length) {
      form.setFieldsValue({ filters: updatedFilters });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 10);
  }, []);

  return (
    <Form form={form} initialValues={initialValues}>
      <Wrapper className={className}>
        <div>
          <Label>
            <FormattedMessage id="lists.name" />
          </Label>
          <Form.Item name="groupName" noStyle>
            <Input
              ref={inputRef}
              disabled={editMode}
              size="medium"
              onChange={onChangeWithSpaceHandling(form, 'groupName')}
            />
          </Form.Item>
        </div>

        <div>
          <Label>
            <FormattedMessage id="dimension_groups.create_edit_form.select_dimensions.label" />
          </Label>
          <Form.Item name="groupDimensions" noStyle>
            <AsyncDimensionsSelect
              chipColoringScheme={chipColoringScheme}
              groupRelatedResults
              isMulti
              preventDimensionGroupSearch
              searchAllTables
              onChange={onGroupDimensionsChange}
            />
          </Form.Item>
        </div>

        {!isEmpty(groupDimensions) && (
          <div>
            <Label>
              <FormattedMessage id="lists.unique_key_modal.set_unique_key_with.label" />
              <Tooltip
                placement="right"
                title={<FormattedMessage id="lists.unique_key_modal.set_unique_key_with.tooltip" />}
              >
                <IconShell color="iconDefault" icon={HelpCircleFilledIcon} />
              </Tooltip>
            </Label>
            <Form.Item name="uniqueKeyColumns" noStyle>
              <Select<DimensionSelectOption, true>
                components={{
                  Option: OptionComponent,
                  MultiValueLabel: MultiLabelComponent,
                }}
                isClearable={false}
                isDisabled={groupDimensions?.length === 0}
                isMulti
                isSearchable
                menuPortalTarget={document.body}
                noOptionsMessage={() => null}
                options={uniqueKeyOptions}
                size="large"
                styles={{
                  multiValue: (base, props) => {
                    const { background, border } = chipColoringScheme(props.data.value);

                    return {
                      ...base,
                      backgroundColor: background,
                      border: `1px solid ${border}`,
                      borderRadius: defaultTheme.borderRadius.xs,
                    };
                  },
                }}
              />
            </Form.Item>
          </div>
        )}

        <div>
          <Form.Item name="filters" noStyle>
            <StyledColumnBasedFilters
              selectedDimensions={
                groupDimensions?.map(({ value }) => value)?.filter((dim) => dim) || []
              }
            />
          </Form.Item>
        </div>

        {!isEmpty(duplicatesData?.rows) && (
          <DuplicateRowsAlert
            setOpenDuplicateRowsModal={setOpenDuplicateRowsModal}
            onDownloadDimGrpDuplicates={onDownloadDimGrpDuplicates}
          />
        )}
      </Wrapper>
    </Form>
  );
};
