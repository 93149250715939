import { Popover as AntPopover } from 'antd';
import { type TooltipRef } from 'antd/es/tooltip';
import { useDismissOnEsc } from 'components/ui/common/hooks/use-dismiss-on-esc';
import { forwardRef, type ReactNode } from 'react';
import { ModalFooter } from '../modal/footer';
import { PopoverFooter, PopoverWrapper } from './styles';
import { type PopoverProps } from './types';

export const Popover = forwardRef<TooltipRef, PopoverProps>(
  (
    {
      cancelText = '',
      confirmLoading,
      confirmDisabled,
      confirmText = '',
      footerExtra,
      $footerPt = 16,
      $footerPx = 12,
      $footerPy = 16,
      bordered = true,
      okButtonProps,
      onConfirm,
      onCancel,
      onEsc,
      type,
      isResizable = false,
      ...props
    },
    ref,
  ): React.ReactElement => {
    useDismissOnEsc({ enable: !!props.open && !!onEsc, dismiss: onEsc });

    return (
      <AntPopover
        destroyTooltipOnHide
        trigger="click"
        {...props}
        ref={ref}
        arrow={false}
        content={
          <PopoverWrapper isResizable={isResizable}>
            {props.content as ReactNode}
            {(cancelText || confirmText) && (
              <PopoverFooter $pt={$footerPt} $px={$footerPx} $py={$footerPy} bordered={bordered}>
                {footerExtra}
                <div>
                  <ModalFooter
                    cancelText={cancelText}
                    confirmLoading={confirmLoading}
                    okButtonProps={okButtonProps}
                    okDisabled={confirmDisabled}
                    okText={confirmText}
                    onCancel={onCancel}
                    onOk={onConfirm}
                  />
                </div>
              </PopoverFooter>
            )}
          </PopoverWrapper>
        }
        overlayClassName={type === 'full-width' ? 'full-ant-popover' : props.overlayClassName}
      />
    );
  },
);

Popover.displayName = 'Popover';
