import { Alert } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { DuplicateValuesAlertContent } from './duplicate-values-alert-content';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
`;

interface Props {
  setOpenDuplicateRowsModal?: React.Dispatch<React.SetStateAction<boolean>>;
  onDownloadDimGrpDuplicates?: () => void;
}

export const DuplicateRowsAlert = ({
  onDownloadDimGrpDuplicates,
  setOpenDuplicateRowsModal,
}: Props): ReactElement => {
  const handleToggle = () => {
    setOpenDuplicateRowsModal?.((prev) => !prev);
  };

  return (
    <Wrapper>
      <Alert
        closable={false}
        message={
          <DuplicateValuesAlertContent
            onDownloadDimGrpDuplicates={onDownloadDimGrpDuplicates}
            onToggle={handleToggle}
          />
        }
        showIcon
        type="error"
      />
    </Wrapper>
  );
};
