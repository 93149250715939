import { Popover } from 'components/ui/atomic-components';
import { useState, type PropsWithChildren, type ReactElement } from 'react';
import styled from 'styled-components';
import { type ListGridRow } from '../../../types';
import { ErrorMessage } from './error-message';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ theme: { spacing } }) => `0 ${spacing[8]}`};
`;

const ErrorIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 6px;
  border-left: 2px solid ${({ theme: { colors } }) => colors.buttonFillNegative};
`;

interface Props {
  row: ListGridRow;
}

export const RowErrorIndicator = ({ children, row }: PropsWithChildren<Props>): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <Wrapper>
      {row.error && (
        <Popover
          content={<ErrorMessage message={row.error} />}
          open={isOpen}
          placement="bottom"
          trigger="hover"
          onOpenChange={handleOpenChange}
        >
          <ErrorIndicator />
        </Popover>
      )}
      {children}
    </Wrapper>
  );
};
