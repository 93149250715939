import { type Granularity, type ID, type NewPeriod, type Status } from 'data';
import { type BarChart } from 'data/boards/charts/types';
import { type DimensionQueryParamsObject } from 'data/dimension';
import { type DataFormatType } from 'data/metrics';
import { type AccessAction } from 'data/roles/permissions';
import { type SaveTransformResponse } from 'data/transformations/types';
import { type DataFormattingType } from 'utils/data-formatter/types';

export interface BigQueryColumnMeta {
  [key: string]: { type: QueryTableColumnDataType };
}

export interface DatasetMaskedColumnConfig {
  allowedUsers?: string[];
  allowedDimensions?: string[];
}

export interface QueryTableConfig {
  maskedColumns?: Record<string, DatasetMaskedColumnConfig>;
  uniqueKey?: string;
  uniqueKeyColumns?: string[];
}

export interface BigQueryChart extends BarChart {
  planPeriods: NewPeriod[];
}

export interface TransformsMeta {
  [columnName: string]: {
    type: QueryTableColumnDataType;
    tableName: string;
    value?: string;
    displayName?: string;
    isCustomColumn?: boolean;
  };
}

export interface BigQueryResponse<T> {
  breakupUrl?: string;
  columnMeta: TransformsMeta;
  columns: string[];
  allColumns: string[];
  rows: T[];
  queryBreakup: boolean;
  dimensionColumns: Record<string, string>;
  result: number;
  rowCount: number;
  totalCount: number;
  pageSize?: number;
  chart: BigQueryChart;
  columnObjects: { name: string; displayName: string }[];
  transformation?: SaveTransformResponse;
  description?: string;
  displayName?: string;
  config?: QueryTableConfig;
  maskedColumns: string[];
  accessAction: AccessAction;
}

export interface BigQueryResponseSchema {
  tables: BigQueryTableSchema[];
}

export interface BigQueryTableSchema {
  name: string;
  displayName: string;
  schema: string;
  columns: {
    name: string;
    dataType: QueryTableColumnDataType;
  }[];
}

export enum QueryAggregationType {
  Count = 'COUNT',
  Sum = 'SUM',
  Average = 'AVG',
  CumulativeSum = 'CUMULATIVE_SUM',
  CumulativeCount = 'CUMULATIVE_COUNT',
  CountUnique = 'COUNT_UNIQUE',
}

export enum QueryTableColumnDataType {
  Boolean = 'BOOL',
  Integer = 'INT64',
  Float = 'FLOAT64',
  Numeric = 'NUMERIC',
  BigNumeric = 'BIGNUMERIC',
  String = 'STRING',
  Bytes = 'BYTES',
  Struct = 'STRUCT',
  Array = 'ARRAY',
  Timestamp = 'TIMESTAMP',
  Date = 'DATE',
  Time = 'TIME',
  DateTime = 'DATETIME',
  Geography = 'GEOGRAPHY',
}

export interface BreakupQueryDrawerParams {
  metricName: string;
  f: DimensionQueryParamsObject;
  startDate: string;
  endDate: string;
  displayName: string;
  displayPeriodKey: string;
  granularity?: Granularity;
  version?: string;
  value?: number;
  metricType?: DataFormattingType;
  metricDataFormat?: DataFormatType;
}

export interface UnderlyingDataRequest {
  metricName?: string;
  dimensions?: DimensionQueryParamsObject;
  startDate?: string;
  endDate?: string;
  granularity?: Granularity;
  version?: string;
  sortOrder?: SortOrder;
  sortedColumn?: string;
  page?: number;
  summarizeBy?: string;
  dontParseBasedOnVersion?: boolean;
}

export interface UnderlyingDataFilterRequest extends UnderlyingDataRequest {
  columnName: string;
}

export type SortOrder = 'ASC' | 'DESC';

export interface Query {
  id: ID;
  name: string;
  statement: string;
  status: Status;
  createdAt: string;
  createdBy: string;
  updatedAt: string | null;
  updatedBy: string | null;
  valueColumn: string;
  aggregation: QueryAggregation;
  breakupUrl?: string;
  type: QueryType;
  tColumn: string;
  dimensionColumns: Record<string, string>;
  dimensions: string;
  filterAttributes?: QueryVisualAttributes;
  transform?: TableTransform;
}

export enum QueryAggregation {
  Count = 0,
  Sum = 2,
  Average = 3,
  CumulativeSum = 10,
  CountUnique = 11,
  CumulativeCount = 12,
}

export enum QueryType {
  Simple = 'SIMPLE',
  Advanced = 'ADVANCED',
}

export enum VisualQueryFilterOperator {
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  NotEqualTo = 'NOT_EQUAL_TO',
  SelectFromValues = 'IN',
  NotOneOf = 'NOT_IN',
  Between = 'BETWEEN',
  EqualTo = 'EQUAL_TO',
  Contains = 'CONTAINS',
  DoesNotContain = 'DOES_NOT_CONTAIN',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  StartsWith = 'STARTS_WITH',
  EndsWith = 'ENDS_WITH',
}

export interface VisualQueryBasicFilterRuleValue {
  value: string | number;
}

export interface VisualQueryFilterRule {
  column: string;
  columnType: QueryTableColumnDataType;
  operator: VisualQueryFilterOperator;
  data?: VisualQueryBasicFilterRuleValue[];
  tableName?: string;
  tableDisplayName?: string;
}

export interface QueryVisualAttributes {
  tableName: string;
  rules?: VisualQueryFilterRule[];
  filterStatement?: string;
  schema: string;
  instanceId?: number;
}

export interface TableSort {
  sortBy: string;
  sortOrder: TableSortOrder;
}

export enum TableSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface TableTransform {
  sortParams?: TableSort[];
  columnOrder?: string[];
  lastUpdatedColumnOrder: string[] | null;
  removedColumns: string[] | null;
}

export enum TransformStepType {
  AddColumn = 'ADD_COLUMN',
  RenameColumn = 'RENAME_COLUMN',
  RemoveColumn = 'REMOVE_COLUMN',
  DeleteColumn = 'DROP',
  ModifyColumnValue = 'MODIFY_COLUMN_VALUE',
  FilterColumnValue = 'FILTER_COLUMN_VALUE',
  OrderColumnValue = 'ORDER_COLUMN_VALUE',
  PositionColumns = 'POSITION_COLUMNS',
  Lookup = 'LOOKUP',
  EditColumn = 'EDIT_COLUMN',
  Map = 'MAP',
  Prompt = 'PROMPT',
  FindAndReplace = 'FIND_AND_REPLACE',
  ModifyColumnType = 'MODIFY_COLUMN_TYPE',
}

export interface BreakupMetric {
  name: string;
  version: string;
  dual: string | null;
}
