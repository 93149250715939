import { type OptionBaseOld, Select } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectorItem } from '../select-item';
import { OptionComponent } from './option-component';

interface Props {
  options: OptionBaseOld[];
  value?: OptionBaseOld;
  isLoading?: boolean;
  width?: number;
  onChange: (value: OptionBaseOld) => void;
}

export const ColumnSelector = ({
  value,
  options,
  onChange,
  isLoading,
  width,
}: Props): ReactElement => {
  return (
    <SelectorItem title={<FormattedMessage id="module.dimension.new_filter.columns_input_label" />}>
      <Select
        components={{ Option: OptionComponent }}
        filterOption={(opt, inputValue) =>
          !!opt.label?.toLowerCase()?.includes((inputValue || '').toLowerCase())
        }
        isLoading={isLoading}
        isSearchable
        options={options}
        placeholder={
          <FormattedMessage id="module.dimension.new_filter.columns_input_placeholder" />
        }
        size="medium"
        value={value || null}
        width={width}
        onChange={(option) => option && onChange(option as OptionBaseOld)}
      />
    </SelectorItem>
  );
};
