import { Select } from 'components/ui/atomic-components';
import { type VisualQueryBasicFilterRuleValue } from 'data/big-query';
import { type ReactNode, useMemo, useState } from 'react';
import { type MultiValue } from 'react-select';
import { type VisualRuleEditRenderCompParams } from '../types';

export const StringMultiSelect: VisualRuleEditRenderCompParams = ({ data, onChange, meta }) => {
  const [inputValue, setInputValue] = useState<string>('');

  const defaultOptions = useMemo(
    () => data?.map((d) => ({ label: d.value, value: d.value })),
    [data],
  );

  const onOptionsChange = (
    newOptions: MultiValue<{
      label?: ReactNode;
      value?: string;
    }>,
  ) => {
    onChange(
      newOptions.map((option) => ({ value: option.value })) as VisualQueryBasicFilterRuleValue[],
    );
  };

  return (
    <Select
      defaultValue={defaultOptions}
      inputValue={inputValue}
      isLoading={meta?.valueOptionsLoading}
      isMulti
      isSearchable
      options={meta?.valueOptions
        ?.filter((option) => (option.value || '').toLowerCase().includes(inputValue?.toLowerCase()))
        ?.slice(0, 200)}
      size="large"
      styles={{
        menu: (base) => ({
          ...base,
          margin: 0,
        }),
        menuList: (base) => ({
          ...base,
          padding: 0,
          maxHeight: 165,
        }),
      }}
      onChange={onOptionsChange}
      onInputChange={(value) => {
        setInputValue(value || '');
      }}
    />
  );
};
