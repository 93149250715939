import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontS, FontXs } from 'styles/typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`;

const Title = styled.div`
  ${FontS};

  color: ${({ theme: { colors } }) => colors.textNegative};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.outlineDefault};
  padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[16]}`};
`;

const Content = styled.div`
  ${FontXs};

  padding: ${({ theme: { spacing } }) => `${spacing[12]} ${spacing[16]}`};
  color: ${({ theme: { colors } }) => colors.textBody1};
`;

interface Props {
  message: string;
}

export const ErrorMessage = ({ message }: Props): ReactElement => {
  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="error" />
      </Title>

      <Content>{message}</Content>
    </Wrapper>
  );
};
