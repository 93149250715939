import { type ValueGetterParams, type ColDef } from 'ag-grid-community';
import { DT_DEFAULT_UNIQUE_KEY } from 'components/modules/modelling/lists/constants';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { defaultTheme } from 'styles/theme';
import { formatName } from 'utils/data-formatter';

export const constructColDefs = (dimensionGroup: DimensionGroup): ColDef[] => {
  return [
    {
      field: 'index',
      colId: 'index',
      headerName: '',
      valueGetter: ({ node }) => {
        return String((node?.rowIndex || 0) + 1);
      },
      pinned: 'left',
      minWidth: 48,
      width: 48,
      lockPosition: 'left',
      cellStyle: { textAlign: 'center', color: defaultTheme.colors.textPlaceholder },
    },
    ...dimensionGroup.columns.map((col) => {
      const isUniqueColumn = col.colName === DT_DEFAULT_UNIQUE_KEY;

      // we might want the custom header in future, so leaving it commented out
      return {
        colId: col.colName,
        hide: isUniqueColumn,
        // headerComponent: HeaderComponent,
        headerName: formatName(col.colName),
        // headerComponentParams: {
        //   headerName: formatName(col.colName),
        //   columnType: col.type,
        //   isUniqueKeyColumn: col.colName === dimensionGroup.uniqueKey,
        // },
        valueGetter: ({ data }: ValueGetterParams) => {
          return data[col.colName];
        },
        pinned: col.colName === dimensionGroup.uniqueKey,
        filter: true,
        sortable: true,
        resizable: true,
      };
    }),
  ];
};
