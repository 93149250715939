import { type OptionBaseOld } from 'components/ui/atomic-components/select';
import { FilterRuleValueSelector } from 'components/ui/filter-rule-value-selector-v2';
import {
  checkIfDataValid,
  isMetaDataRequired,
} from 'components/ui/filter-rule-value-selector-v2/value-renderers';
import {
  type QueryTableColumnDataType,
  type VisualQueryBasicFilterRuleValue,
  type VisualQueryFilterRule,
} from 'data/big-query';
import { constructRuleOperatorOptions } from 'data/big-query/utils';
import { useApplicableColumns, useSelectedColumnValues } from 'data/modelling/dimension';
import { type ReactElement, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { formatName } from 'utils/data-formatter';
import { ColumnSelector } from './column-selector';
import { OperatorSelector } from './operator-selector';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
  padding: ${({ theme }) => theme.spacing[16]};
`;

const Title = styled.div`
  ${FontXs};

  color: ${({ theme }) => theme.colors.textPlaceholder};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

type ColumnSelectOption = OptionBaseOld & {
  sourceTableName: string;
  sourceTableDisplayName: string;
};

interface Props {
  selectedDimensions: string[];
  existingRule?: VisualQueryFilterRule;
  setSelectedRule: (rule?: VisualQueryFilterRule) => void;
}

const SelectWidth = 320;

export const FilterRuleSelector = ({
  selectedDimensions,
  existingRule,
  setSelectedRule,
}: Props): ReactElement => {
  const [selectedColumn, setSelectedColumn] = useState<OptionBaseOld | undefined>();
  const [selectedValue, setSelectedValue] = useState<
    VisualQueryBasicFilterRuleValue[] | undefined
  >();
  const [selectedOperatorOption, setSelectedOperatorOption] = useState<OptionBaseOld | undefined>();
  const selectedColumnType = (selectedColumn as { columnType: QueryTableColumnDataType })
    ?.columnType;
  const intl = useIntl();

  const valuesRequired = isMetaDataRequired(selectedColumnType, selectedOperatorOption?.value);

  const { data: columns, isLoading: columnsLoading } = useApplicableColumns(
    selectedDimensions || [],
  );
  const { data: columnValues, isLoading: valuesLoading } = useSelectedColumnValues(
    valuesRequired ? selectedColumn?.value : undefined,
  );

  const onColumnSelected = (item: OptionBaseOld) => {
    setSelectedColumn(item);
  };

  useEffect(() => {
    setSelectedValue(undefined);
    setSelectedOperatorOption(undefined);
  }, [selectedColumn]);

  useEffect(() => {
    setSelectedValue(undefined);
  }, [selectedOperatorOption]);

  useEffect(() => {
    if (selectedColumn && selectedOperatorOption) {
      const validRule = checkIfDataValid({
        data: selectedValue,
        operator: selectedOperatorOption?.value,
        dataType: selectedColumnType,
      });

      if (validRule) {
        setSelectedRule({
          data: selectedValue,
          column: selectedColumn?.value,
          columnType: selectedColumnType,
          operator: selectedOperatorOption?.value,
          tableName: (selectedColumn as ColumnSelectOption)?.sourceTableName,
          tableDisplayName: (selectedColumn as ColumnSelectOption)?.sourceTableDisplayName,
        });

        return;
      }
    }
    setSelectedRule(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumn, selectedValue, selectedOperatorOption]);

  const columnOptions = useMemo(() => {
    const options: OptionBaseOld[] = [];

    if (columns) {
      Object.values(columns).forEach((tableItem) => {
        tableItem?.columns?.forEach((colItem) => {
          options.push({
            value: colItem.fqName,
            label: formatName(colItem.name),
            sourceTableName: tableItem?.tableName,
            sourceTableDisplayName: tableItem?.tableDisplayName,
            columnType: colItem.type,
          } as OptionBaseOld);
        });
      });
    }

    return options;
  }, [columns]);

  const valueOptions = useMemo(() => {
    return (columnValues || []).map((col) => ({
      value: col.value,
      label: col.value || intl.formatMessage({ id: 'dimensions.blank' }),
    }));
  }, [columnValues, intl]);

  const operatorOptions = useMemo(() => {
    if (selectedColumnType) {
      const { operatorOptions } = constructRuleOperatorOptions({
        columnType: selectedColumnType,
        intl,
      });

      return operatorOptions as OptionBaseOld[];
    }
  }, [selectedColumnType, intl]);

  useEffect(() => {
    if (existingRule && columnOptions) {
      const selectedColOption = columnOptions.find((opt) => opt.value === existingRule.column);

      setSelectedColumn(selectedColOption);
    }
    if (existingRule && operatorOptions) {
      const selectedOperatorOption = operatorOptions.find(
        (opt) => opt.value === existingRule.operator,
      );

      setSelectedOperatorOption(selectedOperatorOption);
      setSelectedValue(existingRule.data);
      setTimeout(() => setSelectedValue(existingRule.data), 1);
    }
  }, [existingRule, columnOptions, operatorOptions]);

  return (
    <Wrapper className="filter-rule-selector">
      <Title>
        <FormattedMessage id="filters" />
      </Title>

      <ColumnSelector
        isLoading={columnsLoading}
        options={columnOptions}
        value={selectedColumn}
        width={SelectWidth}
        onChange={onColumnSelected}
      />

      {Boolean(selectedColumn) && (
        <OperatorSelector
          options={operatorOptions || []}
          value={selectedOperatorOption}
          width={SelectWidth}
          onChange={setSelectedOperatorOption}
        />
      )}

      {Boolean(selectedColumn) && Boolean(selectedOperatorOption) && (
        <FilterRuleValueSelector
          data={selectedValue}
          dataType={selectedColumnType}
          meta={{
            valueOptions,
            valueOptionsLoading: valuesLoading,
          }}
          operator={selectedOperatorOption?.value}
          onChange={setSelectedValue}
        />
      )}
    </Wrapper>
  );
};
