import { type FormProps } from 'components/modules/modelling/lists/crud-components/dimension-group-create-edit-form/types';
import { type ID } from 'data';
import { QueryTableColumnDataType } from 'data/big-query';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { isEqual } from 'lodash';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { type Atleast } from 'utils/typescript';

export const constructPayload = (
  id: ID,
  formData: FormProps,
  currentDimGrp: DimensionGroup,
): Atleast<DimensionGroup, 'id'> => {
  const payload = {
    id,
  } as Atleast<DimensionGroup, 'id'>;

  if (formData.groupName !== currentDimGrp.displayName) {
    payload.displayName = formData.groupName;
  }

  if (
    !isEqual(
      formData.groupDimensions.map((dimGrp) => dimGrp.value),
      currentDimGrp.columns.filter((col) => !!col.source).map((col) => col.source),
    )
  ) {
    const formColumns = formData.groupDimensions.map((dimGrp) => ({
      type: (dimGrp.columnType || QueryTableColumnDataType.String) as QueryTableColumnDataType,
      source: dimGrp.value,
      colName: dimGrp.name || '',
      tableDisplayName: dimGrp.tableDisplayName || '',
      formula: dimGrp.formula ?? null,
    }));

    payload.columns = formColumns;
  }

  const formUniqueKeyColumns = formData.uniqueKeyColumns.map((c) =>
    getColumnNameFromRawDimensionName(c.value),
  );

  if (!isEqual(formUniqueKeyColumns, currentDimGrp.uniqueKeyColumns)) {
    payload.uniqueKeyColumns = formUniqueKeyColumns;
  }

  if (!isEqual(currentDimGrp.filters, formData.filters)) {
    payload.filters = formData.filters;
  }

  return payload;
};
