import { type Optional } from '@tanstack/react-query';
import { type ItemType } from 'components/ui/atomic-components/menu';
import { type RelativeRangeType } from 'components/ui/atomic-components/time-range-panel/v2/relative-panel/types';
import { type AreaConfig } from 'components/ui/chart-components-v2/chart-types/area-chart/types';
import {
  type ComparisonValueType,
  type ComputedComparisons,
  type Granularity,
  type ID,
  type NewPeriod,
} from 'data';
import { type VisualQueryFilterRule } from 'data/big-query';
import { type DateFilterPresetName } from 'data/bootstrap/types';
import { type ObjectWithConversation } from 'data/conversations';
import { type Currency } from 'data/currencies';
import { type Dimension, type DimensionQueryParamsObject } from 'data/dimension';
import { type MetricSortConfig, type DataFormatType, type Metric } from 'data/metrics';
import { type VaryingColGroup, type ColGroup } from 'data/page-template/grid';
import { type Frame } from 'data/reports/types';
import { type SeriesClickEventObject } from 'highcharts';
import { type Dictionary } from 'lodash';
import { type DataFormattingType } from 'utils/data-formatter/types';
import { type StackingType } from './bar-combo';
import { type YAxisConfig, type MetricComboConfig } from './combo';
import { type LineOrBarConfig } from './line-or-bar';
import { type PieChartType } from './pie';
import { type TableChartRowType, type TableChartConfig } from './table';
import { type TextBoxConfig } from './text-box';
import { type WaterfallConfig } from './waterfall';

export enum ChartType {
  Line = 'LINE',
  Bar = 'BAR',
  Gauge = 'GAUGE',
  Scorecard = 'SCORECARD',
  Table = 'TABLE',
  Text = 'TEXT',
  Pie = 'PIE',
  Combo = 'COMBO',
  Funnel = 'FUNNEL',
  Waterfall = 'WATERFALL',
  Empty = 'EMPTY',
  Heading = 'HEADING',
  PlanTable = 'PLAN_TABLE',
  List = 'LIST',
  Area = 'AREA',
}

export type LineOrBarChartType = ChartType.Bar | ChartType.Line;

export enum ChartAggregationType {
  Linear = 0,
  Cumulative = 1,
}

export enum FormatChartLabelType {
  Sum = 'SUM',
  Individual = 'INDIVIDUAL',
  None = 'NONE',
  All = 'ALL',
}

export enum LegendPositionType {
  Hide = 'HIDE',
  Bottom = 'BOTTOM',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum TimeSummaryType {
  TotalInPeriod = 'TOTAL_IN_PERIOD',
  TillDate = 'TILL_DATE',
}

export enum FormatChartCols {
  Planned = 'PLANNED',
  Actual = 'ACTUAL',
  All = 'ALL',
}

export type MetricDimensionOverrides = Record<string, { rows: string[] | null }> | null;

export interface ChartAttributes {
  filteredDimensions?: string[];
  dateOption?: DateFilterPresetName;
  dimMap?: DimensionQueryParamsObject;
  labelType?: FormatChartLabelType;
  legendPosition?: LegendPositionType;
  tillDate?: boolean;
  seriesColorMap?: Record<string, string>;
  seriesColors?: SeriesColors[]; // Currently being used only for statistic series.
  cols?: FormatChartCols;
  columns?: string[];
  rows?: string[];
  versionNames?: string[];
  scenarioNames?: string[];
  metricNames?: string[];
  stackingType?: StackingType;
  pieChartType?: PieChartType;
  comboConfig?: MetricComboConfig;
  sortConfig?: MetricSortConfig[];
  waterfallConfig?: WaterfallConfig;
  tableChartConfig?: TableChartConfig;
  lineOrBarConfig?: LineOrBarConfig;
  lastFilterDimName?: string;
  granularity?: string;
  startDate?: string;
  endDate?: string;
  relativeRange?: RelativeRangeType;
  showSparkline?: boolean;
  sparklineColor?: string;
  textBody?: string;
  textBoxConfig?: TextBoxConfig;
  showVerticalGridLines?: boolean;
  blankRowsPlacement?: { [key: string]: TableChartRowType[] };
  dataFormat?: {
    [metricName: string]: DataFormatType;
  };
  metricTypeMap?: Record<string, DataFormattingType>;
  chartSortConfig?: ChartSortConfig;
  parentChartId?: number;
  summaries?: Granularity[];
  comparisons?: ComputedComparisons[];
  showComparisonsAs?: ComparisonValueType[];
  listInfo?: {
    listId: number;
    cellsEditable?: boolean;
    columnWidthPreference?: Record<string, number>;
    columnOrder?: string[];
    filters?: VisualQueryFilterRule[];
    lockedColumns?: string[];
  };
  axisConfig?: YAxisConfig;
  areaConfig?: AreaConfig;
  fixedFilters?: DimensionQueryParamsObject;
  ai?: ChartSummaryContext[];
  currency?: Currency | null;
  metricDimensionOverrides?: MetricDimensionOverrides;
  hideAggregatesFor?: string[];
  metricNameAlias?: { [metric: string]: string };
  customColumns?: CustomColumn[];
}

export enum SortType {
  AxisSort = 'axis',
  SeriesSort = 'series',
}

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
  LabelAscending = 'LABEL_ASCENDING',
  LabelDescending = 'LABEL_DESCENDING',
}

export interface ChartSortConfigItem {
  order: SortOrder;
  dimensions: { [key: string]: string };
}

export interface ChartSortConfig {
  [SortType.AxisSort]?: ChartSortConfigItem;
  [SortType.SeriesSort]?: ChartSortConfigItem;
}

interface BaseChart extends ObjectWithConversation {
  id: ID;
  boardId: ID;
  name: string;
  description?: string;
  data?: Record<string, number | null>;
  frame?: Frame;
  aggregation?: ChartAggregationType;
  attributes?: ChartAttributes;
  granularDimensions?: Dimension[];
  allGranularDimensions?: Dimension[];
  seriesStacking?: StackingType;
  periodRanges: NewPeriod[];
  granularFilterDimensions?: Dimension[];
  filterDimensions?: Dimension[];
  xAxisTitle?: string;
  periodRangesMap?: Dictionary<NewPeriod>;
}
export interface BarChart extends BaseChart {
  type: ChartType.Bar;
  metrics: Metric[];
}
interface LineChart extends BaseChart {
  type: ChartType.Line;
  metrics: Metric[];
}
interface AreaChart extends BaseChart {
  type: ChartType.Area;
  metrics: Metric[];
}
interface GaugeChart extends BaseChart {
  type: ChartType.Gauge;
  metrics: GaugeMetric[];
  dateOption: DateFilterPresetName;
}
interface ScorecardChart extends BaseChart {
  type: ChartType.Scorecard;
  metrics: ScorecardMetric[];
  dateOption: DateFilterPresetName;
}
interface TableChart extends BaseChart {
  type: ChartType.Table;
  metrics: Metric[];
}

interface PlanTableChart extends BaseChart {
  type: ChartType.PlanTable;
  metrics: Metric[];
}

interface TextChart extends BaseChart {
  type: ChartType.Text;
  metrics: Metric[];
}

interface HeadingChart extends BaseChart {
  type: ChartType.Heading;
  metrics: Metric[];
}

interface EmptyChart extends BaseChart {
  type: ChartType.Empty;
  metrics: Metric[];
}

interface PieChart extends BaseChart {
  type: ChartType.Pie;
  metrics: Metric[];
}

interface ComboChart extends BaseChart {
  type: ChartType.Combo;
  metrics: Metric[];
}

interface FunnelChart extends BaseChart {
  type: ChartType.Funnel;
  metrics: Metric[];
}

interface WaterfallChart extends BaseChart {
  type: ChartType.Waterfall;
  metrics: Metric[];
}

interface ListChart extends BaseChart {
  type: ChartType.List;
  metrics: [];
}

export type Chart =
  | BarChart
  | LineChart
  | GaugeChart
  | ScorecardChart
  | TableChart
  | PieChart
  | ComboChart
  | FunnelChart
  | WaterfallChart
  | TextChart
  | HeadingChart
  | EmptyChart
  | PlanTableChart
  | ListChart
  | AreaChart;

export type PreviewChartResponse = Optional<
  Chart,
  'id' | 'boardId' | 'metrics' | 'periodRanges' | 'name'
>;

export type PreviewChartRequest = {
  boardId: ID;
  chartId?: ID;
  dateOption?: DateFilterPresetName;
  endDate?: string;
  f?: DimensionQueryParamsObject | null;
  boardFilters?: DimensionQueryParamsObject | null;
  chartFilters?: DimensionQueryParamsObject | null;
  granularity?: string;
  startDate?: string;
  filteredDimensions?: string[];
  chart: Partial<Chart>;
  lastFilterDimName?: string;
  boardCurrency?: Currency | null;
  boardGranularity?: string;
};

export interface UpdateChartApiProp {
  added?: Chart[];
  removed?: Chart[];
  updated?: Chart[];
}

export interface GaugeMetric extends Metric, Required<Omit<ColGroup, 'actual' | 'variance'>> {
  min: number;
  current: number;
  max: number;
  displayName: string;
  name: string;
  type: DataFormattingType;
}

export interface ScorecardMetric extends Metric, Required<Omit<ColGroup, 'actual' | 'variance'>> {
  planned?: number;
  headLineValue: number;
  delta: number;
  displayName: string;
  name: string;
  dateOption: DateFilterPresetName;
  variancePercentage?: number | null;
  attainment?: number | null;
}

export type ChartFilterParams = {
  chartFilters: DimensionQueryParamsObject;
  filteredDimensions: string[];
  lastFilterDimName?: string;
  chartCurrency?: Currency;
};

export enum PointItemMenuKey {
  Drilldown = 'drilldown',
  ShowUnderlyingData = 'show-underlying-data',
  RemoveSorting = 'remove-sorting',
  NumberFormat = 'number-format',
  TessInspect = 'tesseract-inspect',
  ShowCalculation = 'show-calculation',
}

export interface ChartContextMenuOptions {
  sortOptions: SortType[];
  drilldownOptions: ItemType[];
}

export type DataContextHandler = (
  e: SeriesClickEventObject,
  options?: ChartContextMenuOptions,
) => void;

export interface SeriesColors {
  statisticInfo?: {
    baselineVersion?: string | null;
    compareStatistic?: VaryingColGroup;
    showCompareStatisticAs?: ('magnitude' | 'percentage')[];
  };
  dimensions?: Record<string, string[]>;
  t?: string;
  metricName: string;
  color: string;
  version: string;
}

export type ContextType = 'context' | 'query';

export interface ChartSummaryContext {
  type: ContextType;
  body: string;
}

export interface CustomColumn {
  name: string;
  displayName: string;
  formula: string;
}
