import { Button } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const ShowRowsButton = styled(Button)`
  color: ${({ theme }) => theme.colors.gray600};
  text-decoration: underline;
`;

const DownloadCsvButton = styled(ShowRowsButton)`
  .btn-content {
    margin-inline: 0;
  }
`;

interface Props {
  onToggle: () => void;
  onDownloadDimGrpDuplicates?: () => void;
}

export const DuplicateValuesAlertContent = ({
  onDownloadDimGrpDuplicates,
  onToggle,
}: Props): ReactElement => {
  return (
    <span>
      <FormattedMessage id="dimension_groups.create_edit_form.duplicate_rows.duplicates_found" />
      <ShowRowsButton size="small" type="link" onClick={onToggle}>
        <FormattedMessage id="dimension_groups.create_edit_form.duplicate_rows.show_rows" />
      </ShowRowsButton>
      <FormattedMessage id="or" />
      <DownloadCsvButton size="small" type="link" onClick={onDownloadDimGrpDuplicates}>
        <FormattedMessage id="dimension_groups.create_edit_form.duplicate_rows.download_csv" />
      </DownloadCsvButton>
    </span>
  );
};
